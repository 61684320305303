<template>
  <div>
    <div class="header">
      <span class="header_left">
        <a-icon
          @click="goBack"
          type="left-circle"
          style="
            font-size: 19px;
            margin-right: 7px;
            line-height: 50px;
            margin-top: 3px;
          "
        />
        {{ $route.query.name }}
        <a-icon
          type="edit"
          @click="changeName"
          style="
            font-size: 15px;
            margin-left: 17px;
            line-height: 50px;
            margin-top: 3px;
          "
        />
      </span>
      <!-- <div class="header_right"></div> -->
    </div>
    <div class="body">
      <div class="body_left">
        <div class="body_left_top">
          会务事务
          <a @click="oneEdit">管理</a>
        </div>
        <div class="wrap">
          <a-tree
            :replace-fields="replaceFields"
            :tree-data="list"
            @select="isSelect"
            blockNode
          >
            <template slot="custom" slot-scope="item">
              <p>
                <span>{{ item.name }}</span>
                <a
                  style="position: absolute; right: 13px"
                  @click="TwoEdit(item.name, item.id)"
                  >管理</a
                >
              </p>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="body_between">
        <a-spin :spinning="spin">
          <div class="body_left_top">任务分类</div>
          <div>
            <a-table
              :customRow="rowClick"
              :columns="invoice"
              class="table"
              :data-source="invoiceData"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return record.id;
                }
              "
              :rowClassName="setRowClassName"
            >
              <span slot="name_hhh" slot-scope="text, record">
                <a-input
                  v-if="record.editable"
                  v-model="record.name"
                  placeholder="请输入"
                  width="240px"
                />
                <template v-else>
                  <diV
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ record.name }}
                  </diV>
                </template>
              </span>
              <span slot="taskTypeName" slot-scope="text, record">
                <div v-if="record.editable">
                  <a-select
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="record.taskTypeCode"
                  >
                    <a-select-option
                      v-for="(item, index) in typeList"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <template v-else>
                  <diV
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ record.taskTypeName }}
                  </diV>
                </template>
              </span>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a
                      @click.stop="saveInvoice(record)"
                      style="margin-right: 5px"
                      >保存</a
                    >
                    <a-popconfirm
                      title="确认取消?"
                      @confirm="() => cancelInvoice(record)"
                    >
                      <a style="margin-right: 5px">取消</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a
                      style="margin-right: 5px"
                      @click.stop="editInvoice(record.id, record)"
                      >编辑</a
                    >
                    <a-popconfirm
                      title="确定删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm.stop="delOne(record.id, 'Three')"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>

            <div class="button" @click="addInvoice">+ 添加</div>
          </div>
        </a-spin>
      </div>
      <div class="body_right">
        <a-spin :spinning="spinning">
          <div class="body_left_top">任务明细</div>
          <div>
            <a-table
              :columns="invoiceRight"
              class="table"
              :data-source="invoiceRightData"
              :pagination="false"
            >
              <span slot="name_hhh" slot-scope="text, record">
                <a-input
                  v-if="record.editable"
                  v-model="record.name"
                  placeholder="请输入"
                />
                <template v-else>
                  <diV
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ record.name }}
                  </diV>
                </template>
              </span>
              <!-- <span slot="taskTypeName" slot-scope="text, record">
                <div v-if="record.editable">
                  <a-select
                    placeholder="请选择"
                    style="width: 100px"
                    v-model="record.taskTypeCode"
                  >
                    <a-select-option
                      v-for="(item, index) in typeList"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <template v-else>
                  <diV
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ record.taskTypeName }}
                  </diV>
                </template>
              </span> -->
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a style="margin-right: 5px" @click="saveRight(record)"
                      >保存</a
                    >
                    <a-popconfirm
                      title="确认取消?"
                      @confirm="() => cancelRight(record)"
                    >
                      <a style="margin-right: 5px">取消</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a style="margin-right: 5px" @click="editRight(record)"
                      >编辑</a
                    >
                    <a-popconfirm
                      title="确定删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="delOne(record.id, 'Four')"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
            <div class="button" @click="addRightList">+ 添加</div>
          </div>
        </a-spin>
      </div>
    </div>

    <!-- 编辑一级菜单 -->
    <a-modal
      @cancel="Onecancel"
      title="会务事务管理"
      :visible="OneVisible"
      width="760px"
      :footer="null"
    >
      <a-table
        :scroll="{ y: 400 }"
        :columns="oneColumns"
        :pagination="false"
        :data-source="oneList"
        :rowKey="
          (record, index) => {
            return record.id;
          }
        "
      >
        <template slot="linNo" slot-scope="text, record, index">
          <a-input
            v-if="record.editable"
            v-model="record.name"
            placeholder="请输入"
            style="width: 290px"
          />

          <template v-else>
            <diV
              style="
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ record.name }}
            </diV>
          </template>
        </template>
        <template slot="sort" slot-scope="text, record, index">
          <div v-if="!record.editable">
            <a v-if="index == oneList.length - 1" @click="move('up', record.id)"
              >上移</a
            >
            <a v-else-if="index == 0" @click="move('down', record.id)">下移</a>
            <a v-else
              ><a @click="move('up', record.id)">上移</a>
              <a @click="move('down', record.id)">下移</a></a
            >
          </div>
          <div v-else></div>
        </template>

        <template slot="type" slot-scope="text, record">
          <a-switch
            :checked="record.isOpen === 1 ? true : false"
            checked-children="开"
            un-checked-children="关"
            default-checked
            v-if="!record.editable"
            @change="OneCardSwitch(record.isOpen === 0 ? 1 : 0, record, 'one')"
          />
          <div v-else></div>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="() => saveOne(record)" style="margin-right: 5px"
                >保存</a
              >

              <a-popconfirm
                title="确认取消?"
                @confirm="() => cancelOne(record)"
              >
                <a style="margin-right: 5px">取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a
                style="margin-right: 5px"
                @click="oneEditMeet(record.id, record)"
                >编辑</a
              >
              <a-popconfirm
                title="确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delOne(record.id, 'one')"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
      <div class="button" @click="addLink">+ 添加</div>
    </a-modal>
    <!-- 编辑二级菜单 -->
    <a-modal
      @cancel="Twocancel"
      :title="twoTitle"
      :visible="TwoVisible"
      width="760px"
      :footer="null"
    >
      <a-table
        :columns="TwoColumns"
        :pagination="false"
        :data-source="TwoList"
        :scroll="{ y: 400 }"
      >
        <template slot="linNo" slot-scope="text, record, index">
          <a-input
            v-if="record.editable"
            v-model="record.name"
            placeholder="请输入"
            width="240px"
          />

          <template v-else>
            <diV
              style="
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ record.name }}
            </diV>
          </template>
        </template>

        <template slot="type" slot-scope="text, record">
          <a-switch
            :checked="record.isOpen === 1 ? true : false"
            checked-children="开"
            un-checked-children="关"
            default-checked
            v-if="!record.editable"
            @change="OneCardSwitch(record.isOpen === 0 ? 1 : 0, record, 'two')"
          />
          <div v-else></div>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a @click="saveTwo(record)" style="margin-right: 5px">保存</a>

              <a-popconfirm
                title="确认取消?"
                @confirm="() => cancelTwo(record)"
              >
                <a>取消</a>
              </a-popconfirm>
            </span>
            <span v-else>
              <a style="margin-right: 5px" @click="editTwo(record.id, record)"
                >编辑</a
              >
              <a-popconfirm
                title="确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delOne(record.id, 'two')"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
      <div class="button" @click="addTwo">+ 添加</div>
    </a-modal>
    <!-- 修改名称 -->
    <a-modal
      title="修改名称"
      @cancel="ChangeNamecancel"
      :visible="changevisible"
      width="460px"
      @ok="ChangeNameOk"
    >
      <a-form-model-item label="模板名称" prop="name" style="display: flex">
        <a-input
          v-model="titleName"
          placeholder="请输入"
          style="width: 260px"
        />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import {
  getMeetcategory,
  addMeetcategory,
  editMeetcategory,
  moveMeetcategory,
  delMeetcategory,
  getMeetcategoryType,
  editTemplate,
} from "@/service/meetingTemplat_api.js";

export default {
  data() {
    return {
      list: [],
      invoice: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name_hhh" },
          width: "40%",
        },
        {
          title: "类型",
          dataIndex: "leix",
          key: "leix",
          scopedSlots: { customRender: "taskTypeName" },
          width: "40%",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: 70,
          scopedSlots: { customRender: "operation" },
        },
      ],
      invoiceRight: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name_hhh" },
          width: "50%",
        },
        // {
        //   title: "类型",
        //   dataIndex: "taskTypeName",
        //   key: "taskTypeName",
        //   scopedSlots: { customRender: "taskTypeName" },
        //   width: "20%",
        // },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      replaceFields: { title: "name", key: "id" },
      invoiceData: [],
      invoiceRightData: [],
      visible: false,
      OneVisible: false,
      oneList: [],
      addtableCount: 0,
      oneColumns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "linNo" },
          width: "50%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          scopedSlots: { customRender: "sort" },
        },
        {
          title: "状态",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      twoTitle: "",
      TwoVisible: false,
      TwoList: [],
      TwoParentId: "",
      TwoColumns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "linNo" },
          width: "55%",
        },
        {
          title: "状态",
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      selectedKeys: "",
      parentIdRight: "",
      typeList: [],
      titleName: "",
      changevisible: false,
      cacheData: [],
      spinning: false,
      spin: false,
    };
  },
  mounted() {
    this.$store.dispatch("isShowNav", false); //恢复导航栏
    this.load();
    this.getType();
    this.titleName = this.$route.query.name;
  },
  methods: {
    // 修改模版名称
    changeName() {
      this.changevisible = true;
    },
    async ChangeNameOk() {
      if (this.titleName !== "") {
        let data = {
          code: this.$route.query.code,
          name: this.titleName,
        };
        const res = await editTemplate(data);
        if (res.code == 0) {
          this.$message.success("修改成功!");
          this.changevisible = false;
          this.$route.query.name = this.titleName;
        }
      } else {
        this.$message.success("请输入名称！");
      }
    },
    ChangeNamecancel() {
      this.changevisible = false;
      this.titleName = this.$route.query.name;
    },
    setRowClassName(record, index) {
      //判断并返回行的样式名称
      if (record.id == this.parentIdRight) {
        return "bg-blue";
      }
    },

    // 一级
    async load() {
      const res = await getMeetcategory(this.$route.query.code, 0);
      if (res.code == 0) {
        res.data.forEach((item) => {
          item.children = [];
          item.scopedSlots = { title: "custom" };
          // item.selectable=false
        });
        this.list = res.data;
        this.loadTwo();
      }
    },
    loadTwo() {
      this.list.forEach((item) => {
        getMeetcategory(this.$route.query.code, item.id).then((res) => {
          if (res.code == 0) {
            item.children = res.data;
          }
        });
      });
    },
    async getType() {
      const res = await getMeetcategoryType();
      if (res.code == 0) {
        this.typeList = res.data;
        console.log("类型", this.typeList);
      }
    },
    async isSelect(selectedKeys, { node }) {
      node.onExpand();
      let flag = false;
      this.list.forEach((item) => {
        if (item.id == selectedKeys[0]) {
          flag = true;
          return;
        }
      });
      if (!flag) {
        this.invoiceData = [];
        this.invoiceRightData = [];
        const res = await getMeetcategory(
          this.$route.query.code,
          selectedKeys[0]
        );
        if (res.code == 0) {
          this.invoiceData = res.data;
          this.selectedKeys = selectedKeys[0];
          console.log(this.selectedKeys);
        }
      }
    },

    rowClick: function (record, index) {
      return {
        on: {
          click: () => {
            if (!record.editable) {
              getMeetcategory(this.$route.query.code, record.id).then((res) => {
                if (res.code == 0) {
                  this.invoiceRightData = res.data;
                  this.parentIdRight = record.id;
                }
              });
            }
          },
        },
      };
    },
    oneEdit() {
      getMeetcategory(this.$route.query.code, 0).then((res) => {
        if (res.code == 0) {
          this.oneList = res.data;
          //  this.oneList.forEach(item=>{this.cacheData.push(Object.assign({}, item))})

          //  this.cacheData=this.oneList.concat()
          //  console.log('打开',this.cacheData);

          //  console.log(this.oneList);

          //   var arr_1 = [];
          //   for (var i = 0; i < this.oneList.length; i++) {
          //  arr_1[i] = this.oneList[i]
          //  arr_1[0].name = 10
        }
      });
      console.log(this.oneList);

      this.OneVisible = true;
    },
    Onecancel() {
      //  this.oneEdit()
      this.list = [];
      this.load();
      this.OneVisible = false;
    },
    // 添加框
    addLink() {
      //  console.log(this.cacheData);
      const editable = this.oneList.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newa = {
        key: Math.random(),
        parentId: 0,
        isOpen: 1,
        id: Math.random(),
        taskTemplateCode: this.$route.query.code,
        name: undefined,
        editable: true,
        //  sort:''
      };
      this.oneList.push(newa);
      console.log(newa);
    },
    async saveOne(record) {
      //  新增的保存
      if (record.key) {
        if (record.name == undefined) {
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          name: record.name,
          parentId: record.parentId, //0为一级任务
          isOpen: record.isOpen, //状态，1开，0关,非必填
          taskTemplateCode: record.taskTemplateCode, //所属模板code
        };
        const res = await addMeetcategory(data);
        if (res.code == 0) {
          this.$message.success("保存成功！");
          record.editable = false;
          this.oneEdit();
        }
      }
      // 编辑的保存
      else {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          id: record.id,
          name: record.name,
          parentId: record.parentId, //0为一级任务
          isOpen: record.isOpen, //状态，1开，0关,非必填
          taskTemplateCode: record.taskTemplateCode, //所属模板code
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          this.$message.success("保存成功！");
          this.oneEdit();
        }
      }
    },

    cancelOne(record) {
      this.oneList.find((item) => {
        if (item.id == record.id) {
          console.log(item);
          // 添加的取消
          if (item.key) {
            const filteredPeople = this.oneList.filter(
              (item1) => item1.id !== item.id
            );
            this.oneList = filteredPeople;
          } else {
            //  编辑的取消
            this.oneEdit();
          }
        }
      });
      //  const target =this.oneList.find((item) => id === record.id);
    },
    //  一级菜单开光修改
    async OneCardSwitch(a, record, type) {
      if (type == "one") {
        let data = {
          id: record.id,
          name: record.name,
          parentId: 0,
          isOpen: a,
          taskTemplateCode: this.$route.query.code,
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          this.$message.success("修改成功！");
          this.oneEdit();
        }
      }
      if (type == "two") {
        let data = {
          id: record.id,
          name: record.name,
          parentId: this.TwoParentId,
          isOpen: a,
          taskTemplateCode: this.$route.query.code,
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          this.$message.success("修改成功！");
          this.TwoEdit(this.twoTitle, this.TwoParentId);
        }
      }
    },
    //  一级菜单移动
    async move(type, id) {
      const editable = this.oneList.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const res = await moveMeetcategory(type, id);
      if (res.code == 0) {
        this.$message.success("移动成功！");
        this.oneEdit();
      }
    },
    // 一级菜单编辑
    oneEditMeet(id, record) {
      const editable = this.oneList.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newData = [...this.oneList];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target.editable = true;
        this.oneList = newData;
      }
    },
    // 删除
    async delOne(id, type) {
      const res = await delMeetcategory(id);
      if (res.code == 0) {
        this.$message.success("删除成功！");
        if (type == "one") {
          this.oneEdit();
        }
        if (type == "two") {
          this.TwoEdit(this.twoTitle, this.TwoParentId);
        }
        if (type == "Three") {
          // this.isSelect([this.selectedKeys]);
          this.getInvoice();
        }
        if (type == "Four") {
          this.getRightData();
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    Twocancel() {
      this.TwoVisible = false;
      this.getNewChild(this.TwoParentId);
    },
    // 二级菜单打开
    async TwoEdit(name, id) {
      this.TwoVisible = true;
      this.twoTitle = name;
      const res = await getMeetcategory(this.$route.query.code, id);
      if (res.code == 0) {
        this.TwoList = res.data;
        this.TwoParentId = id;
        console.log("二级菜单", this.TwoList);
      }
    },
    // 添加二级
    addTwo() {
      const editable = this.TwoList.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newa = {
        key: Math.random(),
        parentId: this.TwoParentId,
        id: Math.random(),
        taskTemplateCode: this.$route.query.code,
        name: undefined,
        editable: true,
        isOpen: 1,
      };
      this.TwoList.push(newa);
    },
    // 保存二级
    async saveTwo(record) {
      if (record.key) {
        if (record.name == undefined) {
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          name: record.name,
          parentId: this.TwoParentId,
          taskTemplateCode: this.$route.query.code,
          isOpen: record.isOpen,
        };
        const res = await addMeetcategory(data);
        if (res.code == 0) {
          record.editable = false;
          this.$message.success("保存成功！");
          this.TwoEdit(this.twoTitle, this.TwoParentId); //更新列表
        }
      } else {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          id: record.id,
          name: record.name,
          parentId: this.TwoParentId,
          taskTemplateCode: this.$route.query.code,
          isOpen: record.isOpen,
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          record.editable = false;
          this.$message.success("保存成功！");
          this.TwoEdit(this.twoTitle, this.TwoParentId); //更新列表
        }
      }
    },
    // 取消二级
    cancelTwo(record) {
      this.TwoList.find((item) => {
        if (item.id == record.id) {
          // 添加的取消
          if (item.key) {
            const filteredPeople = this.TwoList.filter(
              (item1) => item1.id !== item.id
            );
            this.TwoList = filteredPeople;
          } else {
            //  编辑的取消
            this.TwoEdit(this.twoTitle, this.TwoParentId);
            //  const newData=[...this.TwoList]
            //  const target = newData.find((item1) => item1.id === item.id);
            //  if(target){
            //   target.editable = false;
            //   this.TwoList=newData
            //    }
          }
        }
      });
    },
    async getNewChild(id) {
      const newChild = this.list.find((item) => item.id == id);
      newChild.children = [];
      const res = await getMeetcategory(this.$route.query.code, id);
      if (res.code == 0) {
        newChild.children = res.data;
      }
    },
    //  编辑二级
    editTwo(id, record) {
      const editable = this.TwoList.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newData = [...this.TwoList];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target.editable = true;
        this.TwoList = newData;
      }
    },

    addInvoice() {
      if (this.selectedKeys == "") {
        this.$message.warn("请先选择父级菜单！");
        return;
      }
      const editable = this.invoiceData.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newa = {
        key: Math.random(),
        parentId: this.selectedKeys,
        id: Math.random(),
        taskTemplateCode: this.$route.query.code,
        name: undefined,
        taskTypeCode: undefined,
        editable: true,
      };
      this.invoiceData.push(newa);
      console.log(this.invoice);
    },
    async saveInvoice(record) {
      this.spin = true;
      if (record.key) {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.spin = false;
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          name: record.name,
          parentId: this.selectedKeys,
          taskTemplateCode: this.$route.query.code,
          taskTypeCode: record.taskTypeCode,
        };
        console.log(data);

        const res = await addMeetcategory(data);
        if (res.code == 0) {
          record.editable = false;
          this.$message.success("保存成功！");
          this.getInvoice();
          this.parentIdRight = "";
          this.spin = false;
        }
      } else {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.spin = false;
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          id: record.id,
          name: record.name,
          parentId: this.selectedKeys,
          taskTemplateCode: this.$route.query.code,
          taskTypeCode: record.taskTypeCode
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          record.editable = false;
          this.$message.success("保存成功！");
          this.getInvoice();
          this.parentIdRight = "";
          this.spin = false;
        }
      }
    },
    async cancelInvoice(record) {
      console.log(record);

      this.invoiceData.find((item) => {
        if (item.id == record.id) {
          // 添加的取消
          if (item.key) {
            const filteredPeople = this.invoiceData.filter(
              (item1) => item1.id !== item.id
            );
            this.invoiceData = filteredPeople;
          } else {
            //  编辑的取消

            this.getInvoice();
            //  const newData=[... this.invoiceData]
            //  const target = newData.find((item1) => item1.id === item.id);
            //  if(target){
            //   target.editable = false;
            //    this.invoiceData=newData
            //    }
          }
        }
      });
    },
    // 获取任务分类列表
    async getInvoice() {
      console.log("hhh刷新", this.selectedKeys);

      const res = await getMeetcategory(
        this.$route.query.code,
        this.selectedKeys
      );
      if (res.code == 0) {
        this.invoiceData = res.data;
      }
    },
    editInvoice(id, record) {
      const editable = this.invoiceData.find((item1) => item1.editable == true);
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newData = [...this.invoiceData];
      const target = newData.find((item) => id === item.id);
      if (target) {
        target.editable = true;
        this.invoiceData = newData;
      }
    },
    addRightList() {
      if (this.parentIdRight == "") {
        this.$message.warn("请先选择父级菜单！");
        return;
      }
      const editable = this.invoiceRightData.find(
        (item1) => item1.editable == true
      );
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newa = {
        key: Math.random(),
        parentId: this.parentIdRight,
        id: Math.random(),
        taskTemplateCode: this.$route.query.code,
        name: undefined,
        editable: true,
        
      };
      this.invoiceRightData.push(newa);
    },
    async saveRight(record) {
      this.spinning = true;
      if (record.key) {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.$message.warn("请输入名称！");
          this.spin = false;
          return;
        }
        let data = {
          name: record.name,
          parentId: this.parentIdRight,
          taskTemplateCode: this.$route.query.code,
         
        };
        const res = await addMeetcategory(data);
        if (res.code == 0) {
          this.getRightData();
          record.editable = false;
          this.$message.success("保存成功！");
          this.spinning = false;

          // this.isSelect([this.selectedKeys])
        }
      } else {
        if (
          record.name == undefined ||
          record.name == "" ||
          record.name == null
        ) {
          this.spinning = false;
          this.$message.warn("请输入名称！");
          return;
        }
        let data = {
          id: record.id,
          name: record.name,
          parentId: this.parentIdRight,
          taskTemplateCode: this.$route.query.code,
         
        };
        const res = await editMeetcategory(data);
        if (res.code == 0) {
          this.getRightData();
          record.editable = false;
          this.$message.success("保存成功！");

          this.spinning = false;
        }
      }
    },
    getRightData() {
      getMeetcategory(this.$route.query.code, this.parentIdRight).then(
        (res) => {
          if (res.code == 0) {
            this.invoiceRightData = res.data;
          }
        }
      );
    },
    async cancelRight(record) {
      console.log(record);

      this.invoiceRightData.find((item) => {
        if (item.id == record.id) {
          // 添加的取消
          if (item.key) {
            const filteredPeople = this.invoiceRightData.filter(
              (item1) => item1.id !== item.id
            );
            this.invoiceRightData = filteredPeople;
          } else {
            //  编辑的取消
            this.getRightData();
            //  const newData=[... this.invoiceRightData]
            //  const target = newData.find((item1) => item1.id === item.id);
            //  if(target){
            //   target.editable = false;
            //    this.invoiceRightData=newData
            //    }
          }
        }
      });
    },
    editRight(record) {
      const editable = this.invoiceRightData.find(
        (item1) => item1.editable == true
      );
      if (editable) {
        this.$message.warn("请先保存数据！");
        return;
      }
      const newData = [...this.invoiceRightData];
      const target = newData.find((item) => record.id === item.id);
      if (target) {
        target.editable = true;
        this.invoiceRightData = newData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 30px 0px 14px;
  font-size: 16px;
  // line-height: 50px;
  color: black;
  border-top: 1px solid #edeff2;
  border-bottom: 1px solid #edeff2;
  // font-weight: 600;
  // color: $topTitleColor;
  .header_left {
    display: flex;
    align-items: center;
  }
  .header_right {
    font-size: 14px;
    color: rgb(37, 36, 36);
    border: 1px solid #d3d7da;
    display: flex;
    align-items: center;
    height: 30px;
    margin: auto 0;
    padding: 15px 20px;
    cursor: pointer;
  }
}
.body {
  width: 100%;
  min-height: 810px;
  //  height: 100vh;
  background: #edeff2;
  border: 10px solid #edeff2;
  //  white-space:nowrap;
  //  display:flex;
  // justify-content: space-around;
}
.body_left {
  // flex-shrink: 0;
  width: 18%;
  min-height: 450px;
  background: white;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}
.body_between {
  width: 39%;
  min-height: 450px;
  background: white;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 10px;
}
.body_right {
  display: inline-block;
  width: 39%;
  min-height: 450px;
  background: white;
  vertical-align: top;
  padding-bottom: 10px;
}
.body_left_top {
  height: 50px;
  width: 100%;
  line-height: 50px;
  padding: 0px 15px 0px 10px;
  border-bottom: 1px solid #edeff2;
  font-size: 14px;
  color: rgb(37, 36, 36);
  display: flex;
  justify-content: space-between;
}
// ::v-deep .ant-tree-treenode-switcher-close{
//      width:2em  !important;
//      height:2em !important;

// }
.table {
  width: 94%;
  margin: 10px auto;
  overflow-y: scroll;
}
::v-deep .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: rgb(24, 144, 255);
  background: white;
}
.wrap {
  position: relative;
  // width: 350px;
  // background: #fff;
}
.button {
  width: 95%;
  margin: 0 auto;
  height: 40px;
  margin-top: 10px;
  text-align: center;
  line-height: 40px;
  border: 1.5px dashed #c8cacd;
  border-radius: 15px;
  //  color: black;
}
::v-deep .bg-blue {
  background-color: #e6f7ff;
}
// ::v-deep .ant-input{
//   height: 30px;
//   width:240px;

// }
// .input1{
//     width: 100px !important;
//     height: 20px !important;
// }
::v-deep .ant-table-thead > tr > th {
  // padding: 90px 90px;
}
::v-deep .ant-table-tbody > tr > td {
  padding: 16px 16px;
}

::v-deep .ant-input {
  padding: -3px 3px;
}
</style>
